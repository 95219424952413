// 维护记录详情
<template>

    <div class='safeguardRecordDetails baseBg'>
        <backUp></backUp>
        <div class="statusBox">
            设备异常
        </div>

        <div class="cardBox">
            <div class="cardTitle">处理情况</div>
            <el-row :gutter="20">
                <el-col :span="6">
                    <p>
                        <span class="label">处理部门：</span><span class="value">{{detailsInfo.belongDeptName}}</span>
                    </p>
                </el-col>
                <el-col :span="6">
                    <p>
                        <span class="label">处理人：</span><span class="value">{{detailsInfo.operatorName}}</span>
                    </p>
                </el-col>
                <el-col :span="6"
                        v-if="detailsInfo.result">
                    <p>
                        <span class="label">处理结果：</span><span class="value">{{detailsInfo.result.text}}</span>
                    </p>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <p>
                        <span class="label">处理情况：</span><span class="value">{{detailsInfo.resultDescription}}</span>
                    </p>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="downloadFile"
                         v-if="detailsInfo.id">
                        <div class="label">附件：</div>
                        <div class="fileList"
                             style="padding-top: 10px">
                            <com-file-upload-list fileBoxHeight="150px"
                                                  :showUpBtn="false"
                                                  :uploadData="{resourceId: detailsInfo.id, resourceType: 'maintainResultFile'}"
                                                  :neddLoadFile="true"
                                                  :justShowFistOne="false"
                                                  :isNeedPopTemDownload="false"
                                                  :showDownloadBtn="true"
                                                  :showDelBtn="false"></com-file-upload-list>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="cardBox"
             v-if="detailsInfo.locationInfoVo">
            <div class="cardTitle"><img src="../../assets/imgs/icon_didian.png"
                     alt="">客户信息</div>
            <el-row :gutter="20">
                <el-col :span="6">
                    <p>
                        <span class="label">目标客户：</span><span class="value">{{detailsInfo.locationInfoVo.customerCompanyName}}</span>
                    </p>
                </el-col>
                <el-col :span="6">
                    <p>
                        <span class="label">地址：</span><span class="value">{{detailsInfo.locationInfoVo.location}}</span>
                    </p>
                </el-col>
                <el-col :span="6">
                    <p>
                        <span class="label">负责人：</span><span class="value">{{detailsInfo.locationInfoVo.linkName}}</span>
                    </p>
                </el-col>
                <el-col :span="6">
                    <p>
                        <span class="label">联系电话：</span><span class="value">{{detailsInfo.locationInfoVo.linkPhone}}</span>
                    </p>
                </el-col>
            </el-row>
        </div>

        <div class="cardBox">
            <div class="cardTitle"><img src="../../assets/imgs/icon_didian.png"
                     alt="">异常信息</div>
            <el-row :gutter="20">
                <el-col :span="6">
                    <p>
                        <span class="label">报修时间：</span><span class="value">{{detailsInfo.createTime}}</span>
                    </p>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <p>
                        <span class="label">报修时间：</span><span class="value">{{detailsInfo.createTime}}</span>
                    </p>
                </el-col>
            </el-row>
            <el-row :gutter="20"
                    v-if="detailsInfo.equipmentId">
                <el-col :span="24">
                    <p class="deviceItem">
                        <span class="label">异常设备：</span>
                        <span class="equipmentName">{{detailsInfo.equipmentName}}</span>
                        <span class="detailsBtn"
                              @click="details()">查看</span>
                    </p>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <p>
                        <span class="label">异常描述：</span><span class="value">{{detailsInfo.problemDescription}}</span>
                    </p>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="downloadFile">
                        <span class="label">问题附件：</span>
                        <div class="fileList"
                             v-if="detailsInfo.id">
                            <com-file-upload-list fileBoxHeight="150px"
                                                  :showUpBtn="false"
                                                  :uploadData="{resourceId: detailsInfo.id, resourceType: 'maintainProblemFile'}"
                                                  :neddLoadFile="true"
                                                  :justShowFistOne="false"
                                                  :isNeedPopTemDownload="false"
                                                  :showDownloadBtn="true"
                                                  :showDelBtn="false"></com-file-upload-list>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="cardBox">
            <div class="cardTitle"><img src="../../assets/imgs/icon_didian.png"
                     alt="">报修人</div>
            <el-row :gutter="20">
                <el-col :span="6">
                    <p>
                        <span class="label">报修人：</span><span class="value">{{detailsInfo.linkName}}</span>
                    </p>
                </el-col>
                <el-col :span="6">
                    <p>
                        <span class="label">联系电话：</span><span class="value">{{detailsInfo.linkPhone}}</span>
                    </p>
                </el-col>
            </el-row>
        </div>

    </div>

</template>

<script>
import ComFileUploadList from "@/components/upload/comFileUploadList";
import { Loading } from "element-ui";
import API from "@/api/safeguardManage.js";
import backUp from "@/components/backUp";
export default {
    name: "safeguardRecordDetails",

    props: [],

    components: {
        ComFileUploadList,
        backUp,
    },

    data() {
        return {
            detailsInfo: "", // 详情
            photoLis: [], // 问题图片
        };
    },

    created() {},

    mounted() {
        // 维护管理 详情
        this.detailsSafeguardManage();
    },

    methods: {
        // 维护管理 详情
        detailsSafeguardManage() {
            let loading = Loading.service({
                target: document.querySelector(".safeguardDetails"),
            });
            API.detailsSafeguardManage({
                id: this.$route.query.id,
            })
                .then((res) => {
                    loading.close();
                    this.detailsInfo = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 下载文件
        downloadFile(fileName) {
            let url = `${window.configPath.baseUrl}/file/download?file=${fileName}`;
            window.open(url, "_blank").location;
        },
        // 查看设备信息
        details() {
            this.$router.push({
                path: "/stockManage/deviceInoutRecord",
                query: {
                    modelId: this.detailsInfo.modelId,
                    empId: this.detailsInfo.equipmentId,
                },
            });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.safeguardRecordDetails {
    padding: 15px;
}
.statusBox {
    font-size: 36px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ff0000;
}
.cardTitle {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
}
.deviceItem {
    float: left;
}
.deviceItem .detailsBtn {
    padding: 0 10px;
    margin-left: 15px;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    background: #06ccc0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
}
.footer {
    padding-top: 50px;
    text-align: center;
}
.downloadFile .label {
    font-size: 14px;
    font-weight: bold;
    color: #323333;
    width: 110px;
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    padding-top: 10px;
    float: left;
}
.downloadFile .value {
    cursor: pointer;
    transition: all 0.3s;
}
.downloadFile .value:hover {
    cursor: pointer;
    color: #409eff;
    transition: all 0.3s;
}
.fileList {
    float: left;
    padding-top: 0;
}
.fileList ::v-deep .photoLis {
    padding: 0;
    margin: 0;
}
</style>
