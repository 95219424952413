import { render, staticRenderFns } from "./safeguardRecordDetails.vue?vue&type=template&id=724b1aee&scoped=true&"
import script from "./safeguardRecordDetails.vue?vue&type=script&lang=js&"
export * from "./safeguardRecordDetails.vue?vue&type=script&lang=js&"
import style0 from "./safeguardRecordDetails.vue?vue&type=style&index=0&id=724b1aee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724b1aee",
  null
  
)

export default component.exports